<template>
  <Disclosure v-slot="{ open }">
    <DisclosureButton
      :class="{ 'bg-gray-300 shadow-lg' : open }"
      class="
        flex
        justify-between
        items-center
        w-full
        px-4
        py-2
        text-sm
        font-medium
        text-start text-gray-900
        bg-gray-200
        dark:bg-green-100 dark:text-green-900
        rounded-lg
        hover:bg-gray-300
        dark:hover:bg-green-200
        transition
        duration-300
        hover:shadow-lg
        focus:outline-none
        focus-visible:ring
        focus-visible:ring-gray-500
        focus-visible:ring-opacity-75
      "
    >
      <span>{{ title }}</span>
      <ChevronUpIcon
        :class="{ 'transform rotate-180': !open }"
        class="w-5 h-5 text-gray-500 dark:text-green-900"
      />
    </DisclosureButton>
    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-300 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel
        class="px-4 pb-2 text-sm text-gray-500 dark:text-gray-100 space-y-2"
      >
        <slot></slot>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/solid";

export default {
  name: "Accordion",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
