<template>
  <div class="flex justify-center mb-10">
    <div class="w-full lg:w-2/3 p-6">
      <h2 class="text-3xl font-bold text-center dark:text-green-400 my-10">
        سوالات متداول
      </h2>

      <div  class="leading-relaxed bg-gray-50 dark:bg-gray-800 shadow rounded p-6 space-y-4" >
        <Accordion title="برای ورود به بازار ارزهای دیجیتال چه اطلاعاتی لازم است؟">
          <p class="text-right">
            ابتدا ریسک این کار را در نظر بگیرید. همانطور که ممکن است سرمایه‌تان
            به واسطه خرید رمز ارزها چند برابر شود، احتمال ضرر و زیان شدید نیز
            وجود دارد. سپس باید نحوه کار با کیف پول‌ها را به خوبی فرا بگیرید.
            ارزهای دیجیتال اغلب غیر متمرکز هستند، به این معنی که کنترل و اختیار
            دارایی‌‌ در دست خودتان قرار دارد. اگر با بی‌احتیاطی یا به خاطر عدم
            آشنایی، مشکلی برای والت شما بروز کند، برای همیشه پولتان را از دست
            خواهید داد. تاکید می‌کنیم هیچ راه بازگشتی در این صورت وجود ندارد.
          </p>

          <p class="text-right">
            سپس باید با تحلیل تکنیکال و تحلیل فاندامنتال آشنایی پیدا کرده یا از
            افراد متخصص در این زمینه درباره بهترین ارزهای دیجیتال در هر بازه
            زمانی به منظور سرمایه‌گذاری سوال کنید. نباید صرفا به خاطر شنیدن یک
            اسم، یا مطلع شدن از رشد بالای یک ارز در یک دوره کوتاه، وارد بازار آن
            شوید.
          </p>
        </Accordion>

        <Accordion title="آیا بدون هیچ اطلاعات خاصی میتوان وارد بازار ارزهای دیجیتال شد؟">
          <p class="text-right">
            متاسفانه افراد زیادی با پی بردن به دستیابی یک سری نفرات به سودهای
            نجومی به واسطه سرمایه‌گذاری در ارزهای دیجیتال، بدون کسب دانش و
            اطلاعات کافی در این رابطه به آن ورود پیدا می‌کنند. احتمال ضرر کردن
            در این شرایط بسیار بالا است. به عنوان مثال افراد زیادی به خاطر عدم
            آشنایی با نحوه کار کردن با کیف پول‌های ارز دیجیتال و همچنین خرید
            بدون در نظر گرفتن ریسک نوسانات بالا، سرمایه خود را از دست داده‌اند.
            به همین دلیل ابتدا باید با مبانی کریپتوکارنسی آشنایی پیدا کنید.
          </p>
        </Accordion>

        <Accordion title="ریسک سرمایه‌گذاری در بازار ارزهای دیجیتال چقدر است؟">
          <p class="text-right">
            یکی از مهم‌ترین خاصیت‌های بازار کریپتوکارنسی در مقایسه با بازارهای
            مالی قدیمی‌تر، داشتن نوسان‌های شدید و متعدد است، به طوری که این
            احتمال وجود دارد حتی طی کمتر از یک ساعت، قیمت یک رمز ارز بالای ده
            درصد صعود یا سقوط کند. پس احتمال کسب سود فراوان، همراه احتمال مواجه
            شدن با ضرر و زیان بالا است.
          </p>
        </Accordion>

        <Accordion title="چگونه می‌توانم ارز دیجیتال بخرم؟">
          <p class="text-right">
            برای خرید ارز دیجیتال در سایت گرندبیتکس باید مراحل زیر را طی کنید:
          </p>

          <ul class="list-inside list-decimal space-y-2">
            <li>
              <span class="font-semibold">ثبت نام یا ورود به حساب کاربری:</span>

              <p class="text-right">
                اگر برای اولین بار می باشد که در سایت گرندبیتکس قصد خرید دارید
                باید ثبت نام کنید و برای ثبت نام نیز ابتدا بایستی احراز هویت شما
                انجام شود.در صورت داشتن حساب کاربری و تایید احراز هویت کافیست
                وارد حساب کاربری خود شوید و خرید کنید.
              </p>
            </li>

            <li>
              <span class="font-semibold">احراز هویت:</span>

              <p class="text-right">
                برای احراز هویت کافیست مراحل احراز هویت را کامل انجام دهید و بعد
                از تکمیل مراحل احراز هویت منتظر بمانید تا حساب کاربریتان تایید
                هویت شود
              </p>
            </li>

            <li>
              <span class="font-semibold">ثبت درخواست خرید:</span>
              <p class="text-right">
                برای ثبت خرید می‌بایست ابتدا دکمه ورود را بزنید و وارد حساب
                کاربری خود شوید سپس از داشبورد گزینه خرید از ما را انتخاب کنید و
                مراحل خرید را انجام دهید.
              </p>
            </li>
          </ul>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/utilities/Accordion.vue";

export default {
  components: {
    Accordion,
  },
};
</script>

<style></style>
